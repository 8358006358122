import request from '@/utils/request'

export function search(key) {
    return request({
        url: '/book/search/'+key,
        method: 'get',
    })
}
export function query(data) {
    return request({
        url: '/book/query/',
        method: 'post',
        data
    })
}
export function bookDetail(bookId) {
    return request({
        url: '/book/'+bookId,
        method: 'get',
    })
}
export function chapterDetail(bookId, chapterId) {
    return request({
        url: '/chapter/'+bookId+'/'+chapterId,
        method: 'get',
    })
}